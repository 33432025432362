@font-face {
  font-family: 'ArialRoundedMt';
  src: local(ArialRoundedMt), url(fonts/arial_rounded_bold.ttf);
}

@font-face {
  font-family: 'ARLRDBD';
  src: local(ARLRDBD), url(fonts/ARLRDBD.ttf);
}

@font-face {
  font-family: 'Verlag';
  src: local(Verlag), url(fonts/Verlag-Book.otf);
}
